<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[视频带货]每天2-3小时，会刷抖音就行</p>
    <div>
      <div class="psd">很多人会说通过抖音不赚钱，可能觉得：</div>
      <p class="mico">
        <span class="col"
          >一是：认为抖音只是用来消遣的，每天就刷刷笑笑消磨时间。</span
        >
        <span>二是：认为赚钱只有那些大KOL，没粉丝没颜值根本赚不了钱。</span>
        <span class="col"
          >三是：不相信自己，从小到大做成的事情也没多少，更别说可以靠抖音赚钱！</span
        >
        <span
          >四是：不知道抖音现在卖的产品都是来自抖音小店，而开个抖音小店则是现在最大的风口和红利期。</span
        >
      </p>
      <p class="psd">这四点，恰恰是现如今大多数人对抖音最大的误解。</p>
      <h5 class="col psd">2022年抖音已经重新被定义！</h5>
      <p class="psd">
        事实上，即使<span class="col">0个粉丝</span
        >，一个视频也不用拍，也不用开直播，<span class="col"
          >也能在抖音开个店铺进行卖货。</span
        >
      </p>
      <p class="psd">
        而且店铺的产品无需囤货、不用进货、货源一件代发，这就是2022年副业风口：<span
          class="col"
          >抖音无货源小店</span
        >
      </p>
      <h5 class="psd">如果你也想月入一万以上。</h5>
      <p class="col max psd">
        请仔细看，认真看，耐心看完这篇文章，将会颠覆你对抖音所有的认知。
      </p>
      <h4 class="pos">无货源抖音小店</h4>
      <h2 class="col pos">疯狂敛财</h2>
      <p class="psd">
        大家一听无货源抖音小店，第一时间想到的就是<span>没货源怎么卖？</span>
      </p>
      <p class="psd">
        <span class="col">其实这里的无货源并不是只没有货源，</span
        >我们今天来重新认识一下什么是无货源店铺。
      </p>
      <h3 class="psd">无货源店铺：</h3>
      <p class="psd">
        这里无货源的意思是指<span class="col"
          >不需要我们进货囤货，避免资金压力；</span
        >
      </p>
      <p class="psd">
        而我们所需要做的就是<span class="col"
          >把货源的产品图片上传到我们自己的店铺里面进行售卖</span
        >
      </p>
      <p class="psd">
        当有用户下单后，我们再把用户的<span class="col"
          >收件信息发给货源，货源再进行一件代发，售后也是货源负责。</span
        >
      </p>
      <p class="psd">
        利润如何产生：<span class="col">赚差价；</span
        >货源的产品加价上架到我们的店铺后，用户下一单，我们就赚一单的差价，如下图
      </p>
      <img src="@/assets/images/spdhs.jpg" alt="" class="pic" />
      <h2 class="psd">通过这个女士防晒衫，就可以得出一个很好的案例。</h2>
      <p class="psd col">
        货源的成本价格是49.9元，我们在抖音的售价是149元，一件净赚100元，目前已售是4066件，也就是40万利润！
      </p>
      <p class="psd">
        像其他货源的产品同样的道理，直接加价上架到我们的店铺出售就行。
      </p>
      <span class="psd">这就是无货源的一个完整操作流程。</span>
      <h3 class="psd pos max">怎么做？</h3>
      <div class="psd">需要准备2卡+1证</div>
      <p class="msg">
        <span>2卡：本人的银行卡、正常办理的手机卡</span>
        <span>1证：本人的身份证</span>
      </p>
      <p class="msg pos">
        <span class="col">需年满18周岁（年龄不够勿扰）</span>
        <span>一天至少抽出2个小时时间来经营</span>
      </p>
      <p class="psd">
        执行力强，且做事细心，能够多方面考虑
        <span class="col">不想付出就想赚钱，整天想着做白日梦的勿扰；</span>
        轻易得到的东西往往不会珍惜！
      </p>
      <p class="psd">
        接下来就交给抖音官方服务市场的运营商，会建立多对一的服务指导群，<span
          class="col"
          >一对一的服务教学，</span
        >并且帮你注册开通抖音小店、选品、上架商品以及管理订单发货，<span
          class="col"
          >你需每天查看每日订单、确认发货、以及学习电商的相关知识。</span
        >
      </p>
      <ul class="msg psd">
        <li>如果你想让收入蹭蹭往上涨；</li>
        <li>如果你想开辟更多的赚钱路径，摆脱死工资；</li>
        <li>如果你想充分学习了解抖音的玩法和机制。</li>
      </ul>
      <div class="msg">
        <span>却又找不到可以挣钱的副业做，</span>
        <span>不妨加一下抖音官方服务市场服务商的微信</span>
      </div>
    </div>
    <div>
      <div class="title">【报名方式】</div>
      <img src="@/assets/images/spdh.jpg" alt="" />
    </div>
    <div>
      <div class="title">【免责声明】</div>
      <p class="msg">
        <span>1、涉及到交纳会费、看淘宝淘气值、花呗等相关的内容都是诈骗。</span>
        <span>2、未标明有费用的岗位，涉及到收费的都可联系客服反馈。</span>
        <span>3、请大家仔细辨别相关招聘内容，谨防诈骗。</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
  .mico {
    margin: 0 10px;
    padding: 5px 10px;
    border-left: 5px solid #ccc;
  }
  .mico span {
    padding: 5px 0;
    display: block;
    line-height: 26px;
  }
  .col {
    color: #711a0c;
  }
  .max {
    font-weight: bolder;
  }
  .pos {
    text-align: center;
    margin: 30px 0;
  }
}
</style>
